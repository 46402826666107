
a{
    text-decoration: none;
      color: inherit;
}
.rowParent {
    display: flex; /* Comment: Displays the container as a flex container */
    /* justify-content: space-between;  */
}
.rowParent:hover {
    background-color: rgb(0, 218, 218);
}
.rowParentTH {
    display: flex; /* Comment: Displays the container as a flex container */
    background: aliceblue;
    margin-bottom: 1%;
    border-bottom: 1px solid;
}
.rowOdd{
    background-color: rgb(255, 255, 255);
}
.rowEven{
    background-color: rgb(230, 230, 230);
}
.rowOdd2{
    background-color: rgb(255, 255, 255);
}
.rowEven2{
    background-color: rgb(230, 230, 230);
}
.desktop-only {
    display: none; 
}
.mobile-only {
    display: none; 
}
.hideExtra{
    display: none !important;
}
.showExtra{
    display: flex;
}
.showAllExtra{
    margin-bottom: 3px;
    z-index: 100;
    position: absolute;
}
.rowselected{
    background-color: rgb(0, 173, 173);
}
.canClick {
    cursor: pointer;
}
.bigExpandCollapse{
    display: none;
}
.smallExpandCollapse{
    display: none;
}
@media (max-width: 1149px){
    .smallExpandCollapse{
        display: flex;
        width: 26px;
    }
}
@media (min-width: 1150px){
.bigExpandCollapse{
    display: flex;
    width: 90px;
    margin-bottom: 3px;
}
}
@media (min-width: 768px) {
    .desktop-only {
        display: block; 
    }
    .data{
        width: 25%;
        justify-content: left;
        text-align: center;
        padding: 2px 10px
    }
    .data2{
        width: 48%;
        justify-content: left;
        text-align: center;
        padding: 2px 10px
    }
    .subData{
        padding: 3px 10px;
    }
}

@media (max-width: 767px) {
    .mobile-only {
        display: block; 
    }
    .data{
        width: 33%;
        justify-content: left;
        text-align: center;
        padding: 3px 10px;
    }
    .data2{
        width: 33%;
        justify-content: left;
        text-align: center;
        padding: 3px 10px;
    }
    .dataComboBox{
        width: 33%;
    word-wrap: anywhere;
    text-align: center;
    }
    .rowParent {
        margin-top: 2%;
    }
}
@media (max-width: 445px){
    .changeNumBig{
        display: none;
    }
    .changeNumSmall{
        display: initial;
    }
}
@media (min-width: 446px){
    .changeNumBig{
        display: initial;
    }
    .changeNumSmall{
        display: none;
    }
}