.notDueSoon{
background-color: #81f17e;
}
.DueSoon{
background-color: #f5d76e;
}
.Due{
background-color: #ff540099;
}
.late{
    background-color: #fd95af;
}
.closed{
    background-color: #95adfd;
}
.borderRadius25{
    border-radius: 25px;
    padding: 0 4px;
}