.darkModeButtonOff{
    background-color: black;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
    border: 2px solid white;
}
.darkModeButtonOn{
    background-color: white;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    border: 2px solid black;
    cursor: pointer;
}
.darkModePageOn{
    background-color: black !important;
  color: dimgray !important;
}
.darkModePageOff{
    background-color: #f8f8f8!important;
    color: black !important;
}
.headerStyle {
    margin:0px !important;
    padding-left: 10%;
  }
.onehundred{
    min-height: 100%;
  height: max-content;
}