.animated-progress-bar {
    background-color: #e0e0df;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-fill {
    height: 20px;
    background-color: #76c7c0;
    border-radius: 5px;
    transition: width 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }
  