.circle{
    border: 1px solid #aaa;
    box-shadow: inset 1px 1px 3px #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    display: inline-block;
    vertical-align: middle;
    background: #aaaaaa4f;
}
.circle:before,
.circle:after{
    content:'';position:absolute;top:0;left:0;right:0;bottom:0;
}
/* PLUS */
.circle.plus:before,
.circle.plus:after {
    background:green;
    box-shadow: 1px 1px 1px #ffffff9e;
}
.circle.plus:before{
    width: 2px;
    margin: 3px auto;
}
.circle.plus:after{
    margin: auto 3px;
    height: 2px;
    box-shadow: none;
}
/* MINUS */
.circle.minus:before{
    background: #cc0000;
    margin: auto 3px;
    height: 2px;
    box-shadow: 0px 1px 1px #ffffff9e;
}
